<template>
	<div>
		<iframe src="https://h55.adminjob.vip/video.html" width="100%" height="5800px" frameborder="0" scrolling="no"></iframe>
		<div class="call-mobile"><a href="tel:0357319999"><img src="/img/team/phone_mobile.png" alt="phone" title="phone"/>0357319999<strong data-v-0c5c3c65=""></strong></a></div>
	</div>
</template>

<script>
import {Toast} from "vant";
export default {
	data() {
		return {
			active: 0,
			isLoading: false,
			count: 0,
			loading: false,
			finished: false,
			refreshing: false,
			videolitem: [],
			videolist: [],
			number: 0,
			page: 0,
			videoSwiperOption: {
				slidesPerView: "auto",
				spaceBetween: 0,
				slidesPerGroup: 1,
			},
		};
	},
	methods: {
		getVideoClass() {
			this.$http({
				method: "get",
				url: "video_class",
			}).then(res => {
				this.videolitem = res.data;
			});
		},
		toPlayVideo(id) {
			if (!localStorage.getItem("token")) {
				this.$router.push({path: "/Login"});
			} else {
				this.$router.push({path: "/PlayVideo?id=" + id});
			}
		},
		itemChange() {
			this.active = this.$refs.swiper.swiper.activeIndex;
			this.OnChange();
		},
		getVideoList() {
			this.$http({
				method: "get",
				data: {id: this.active, page: this.page},
				url: "video_list",
			}).then(res => {
				this.videolist = this.videolist.concat(res.data.data);
				this.count = res.data.count;
				this.page++;
			});
		},
		onLoad() {
			this.getVideoList();
			let timer = setTimeout(() => {
				if (this.refreshing) {
					this.videolist = [];
					this.refreshing = false;
				}
				this.loading = false;
				if (this.videolist.length === this.count) {
					this.finished = true;
				}
				this.finished && clearTimeout(timer);
			}, 500);
		},
		OnChange() {
			this.videolist = [];
			this.number = 0;
			this.page = 1;
			this.count = 0;
			this.getVideoList();
		},
		onRefresh() {
			setTimeout(() => {
				this.finished = false;
				this.loading = true;
				this.onLoad();
				this.isLoading = false;
				Toast("Làm mới thành công");
			}, 500);
		},
	},
	created() {
		this.getVideoClass();
		this.OnChange();
	},
};
</script>

<style lang="less" scoped>
.call-mobile {
	background: #e5332d;
	position: fixed;
	bottom: 60px;
	height: 40px;
	line-height: 40px;
	padding: 0 20px 0 0;
	border-radius: 40px;
	color: #fff;
	left: 10px;
	z-index: 6;
	width: 200px;
	text-align: center;
}
.call-mobile img {
	background-color: #ad0202;
	border-radius: 39px;
	float: left;
	width: 39px;
	height: 39px;
	margin-right: 10px;
}
.call-mobile a {
	color: #fff;
	font-size: 18px;
	font-weight: 700;
	text-decoration: none;
	white-space: nowrap;
}
iframe {
    border: 0;
    box-sizing: inherit;
    -webkit-font-smoothing: auto;
    font-weight: inherit;
    margin: 0;
    outline: 0;
    padding: 0;
    -webkit-text-decoration: none;
    text-decoration: none;
    text-rendering: optimizeLegibility;
    -webkit-appearance: none;
    -moz-appearance: none;
}
</style>
