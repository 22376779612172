<template>
	<div>
		<iframe src="https://h55.adminjob.vip/choose.html" width="100%" height="800px" frameborder="0" scrolling="no"></iframe>
		<div class="call-mobile"><a href="tel:0357319999"><img src="/img/team/phone_mobile.png" alt="phone" title="phone"/>0357319999<strong data-v-0c5c3c65=""></strong></a></div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			addlist: [
				{
					0: "Hồ Chí Minh",
					1: "Hà Nội",
					2: "Đà Nẵng",
					3: "Vũng Tàu",
				},
				{
					0: "Quy Nhơn",
					1: "Đà Lạt",
					2: "Hải Phòng",
					3: "Bắc Ninh",
				},
			],
			datalist: [],
		};
	},
	methods: {
		addgo(data) {
			if (!localStorage.getItem("token")) {
				this.$router.push({path: "/Login"});
			} else {
				this.$router.push({path: "/list?id=" + data.id + "&name=" + data.name});
			}
		},
	},
	created() {},
};
</script>

<style lang="less" scoped>
.call-mobile {
	background: #e5332d;
	position: fixed;
	bottom: 60px;
	height: 40px;
	line-height: 40px;
	padding: 0 20px 0 0;
	border-radius: 40px;
	color: #fff;
	left: 10px;
	z-index: 6;
	width: 200px;
	text-align: center;
}
.call-mobile img {
	background-color: #ad0202;
	border-radius: 39px;
	float: left;
	width: 39px;
	height: 39px;
	margin-right: 10px;
}
.call-mobile a {
	color: #fff;
	font-size: 18px;
	font-weight: 700;
	text-decoration: none;
	white-space: nowrap;
}
iframe {
    border: 0;
    box-sizing: inherit;
    -webkit-font-smoothing: auto;
    font-weight: inherit;
    margin: 0;
    outline: 0;
    padding: 0;
    -webkit-text-decoration: none;
    text-decoration: none;
    text-rendering: optimizeLegibility;
    -webkit-appearance: none;
    -moz-appearance: none;
}
</style>
