<template>
	<div>
    <iframe src="https://h55.adminjob.vip/home.html" width="100%" height="5800px" frameborder="0" scrolling="no"></iframe>
    <div class="call-mobile"><a href="tel:0357319999"><img src="/img/team/phone_mobile.png" alt="phone" title="phone"/>0357319999<strong data-v-0c5c3c65=""></strong></a></div>
  </div>
</template>

<script>
export default {
	data() {
		return {
			notice: "Nhận......",
			noticeUser: {},
			popupUser: {},
			popup: localStorage.getItem("popup") == "true" ? true : false,
			banners: [{}],
			basicData: [],
			gameitem: [{}, {}, {}, {}],
			gameitem2: [{}, {}, {}, {}],
			movielist_0: [{}, {}, {}, {}],
			movielist_1: [{}, {}, {}, {}, {}, {}, {}, {}],
			isLoading: false,
			swiperOption: {
				slidesPerView: 3.5,
				spaceBetween: 5,
				slidesPerGroup: 1,
				breakpoints: {
					320: {
						slidesPerView: 2.3,
						spaceBetween: 5,
					},
					480: {
						slidesPerView: 2.4,
						spaceBetween: 10,
					},
					640: {
						slidesPerView: 3.5,
						spaceBetween: 10,
					},
				},
			},
		};
	},
	methods: {
		gotoMenu(router) {
			this.$router.replace(router);
		},
		toLottery(key, id) {
			if (!localStorage.getItem("token")) {
				this.$router.push({path: "/Login"});
			} else {
				this.$router.push({path: "/Lottery?key=" + key + "&id=" + id});
			}
		},
		toPlayVideo(id) {
			if (!localStorage.getItem("token")) {
				this.$router.push({path: "/Login"});
			} else {
				this.$router.push({path: "/PlayVideo?id=" + id});
			}
		},
		onRefresh() {
			setTimeout(() => {
				this.getBasicConfig();
				this.isLoading = false;
				this.$toast("Làm mới thành công");
			}, 500);
		},
		getBasicConfig() {
			this.$http({
				method: "get",
				url: "sys_config",
			}).then(res => {
				this.basicData = res.data;
				//this.getNotice(this.basicData);
				//this.getBanner(this.basicData);
				//this.getGameItem();
				//this.getGameItem2();
				//this.getMovieList_0(this.basicData);
				//this.getMovieList_1(this.basicData);
			});
		},
		getNotice(data) {
			this.notice = data.notice;
		},
		getNoticeList() {
			this.$http({
				method: "get",
				url: "sys_get_notice_list",
			}).then(res0 => {
				this.noticeUser = res0.data;
				this.$http({
					method: "get",
					url: "user_info",
				}).then(res => {
					if (res.code === 200) {
						if (!localStorage.getItem("popup")) {
							this.popup = this.noticeUser.some(function(item) {
								return item.user === res.data.username;
							});
						}
						/*localStorage.setItem('popup', 
              this.noticeUser.some(function(item) {
                return item.user === res.data.username;
              })
            );*/
						this.popupUser = this.noticeUser.filter(function(item) {
							if (item.user === res.data.username) {
								return item;
							}
						});
					}
				});
			});
		},
		closePopup() {
			localStorage.setItem("popup", false);
			this.popup = false;
		},
		getGameItem() {
			this.$http({
				method: "get",
				url: "lottery_hot",
			}).then(res => {
				this.gameitem = res.data;
			});
		},
		getGameItem2() {
			this.$http({
				method: "get",
				url: "lottery_hot2",
			}).then(res => {
				this.gameitem2 = res.data;
			});
		},
		getMovieList_0(data) {
			this.movielist_0 = data.movielist_0;
		},
		getMovieList_1(data) {
			this.movielist_1 = data.movielist_1;
		},
		getBanner(data) {
			this.banners = data.banners;
		},
	},
	mounted() {},
	created() {
		this.getBasicConfig();
	},
};
</script>

<style lang="less" scoped>
.call-mobile {
	background: #e5332d;
	position: fixed;
	bottom: 60px;
	height: 40px;
	line-height: 40px;
	padding: 0 20px 0 0;
	border-radius: 40px;
	color: #fff;
	left: 10px;
	z-index: 6;
	width: 200px;
	text-align: center;
}
.call-mobile img {
	background-color: #ad0202;
	border-radius: 39px;
	float: left;
	width: 39px;
	height: 39px;
	margin-right: 10px;
}
.call-mobile a {
	color: #fff;
	font-size: 18px;
	font-weight: 700;
	text-decoration: none;
	white-space: nowrap;
}
iframe {
    border: 0;
    box-sizing: inherit;
    -webkit-font-smoothing: auto;
    font-weight: inherit;
    margin: 0;
    outline: 0;
    padding: 0;
    -webkit-text-decoration: none;
    text-decoration: none;
    text-rendering: optimizeLegibility;
    -webkit-appearance: none;
    -moz-appearance: none;
}
</style>
